let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Dale Hillis",
    "role": "Software Developer and Data Scientist",
    "linkedinId":"dale-h-31281912a",
    "roleDescription": "I love to play about data and trying to see how it could be better utilised in every day life.",
    "socialLinks":[
        {
          "name":"linkedin",
            "url":"https://www.linkedin.com/in/dale-h-31281912a/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/",
          "className":"fa fa-github"
        },
      ],
    "aboutme":"I am a recent graduate of Heriot-Watt University in Edinburgh. From around the age of 10 I started to play about with computers, from there I started my journey that resulted in my passion for problem solving and data analysis.",
    "address":"Edinburgh, UK",
    "education":[
      {
        "UniversityName":"Heriot-Watt University",
        "specialization":"Bsc (Hons) Computer Science",
        "MonthOfPassing":"June",
        "YearOfPassing":"2022",
            "Achievements":"Created interactive web based visulisation of the COVID-19 pandemic using D3. Used several learning models within Sklearn to detect a COVID-19 infection from a chest x ray scan. Programmed a particle swarm optimisation and gradient descent algorithm for an artificial neural network from the ground up."
      },
      {
        "UniversityName":"New College Lanarkshire",
        "specialization":"HNC Computing",
        "MonthOfPassing":"June",
        "YearOfPassing":"2018",
      }
    ],
    "work": [
        {
        "CompanyName": "Costa Coffee",
        "specialization": "Barista",
        "MonthOfLeaving": "Current Day",
        "YearOfLeaving": "",
        "Achievements": "Serve over a large variety of hot and cold crafted drinks to customers throughout the day. Efficiently handle both till and drink stations to get the average total wait time to under 3 minutes."
        },
      {
        "CompanyName":"Self-Employed",
        "specialization":"Mathematics and Computer Science Tutor",
        "MonthOfLeaving":"September",
        "YearOfLeaving":"2022",
        "Achievements":"Developed and maintained the website using JavaScript and React. Composed all of the promotional material used. Composed all lesson material. Facilitated lessons from S2 up until HNC level. Handled any queries from both parents and tutees. Achieved an average grade increase of 2 grade bands."
      },
      {
        "CompanyName":"Bedlam Paintballing",
        "specialization":"Games Marshal",
        "MonthOfLeaving":"March",
        "YearOfLeaving":"2020",
        "Achievements":"Greeted customers to the game site at the start of the day. Guided guests through the insurance forms. Handled games of up to 30 people. Coordinated with other game marshals to ensure a smooth transition between games. "
       },
       {
        "CompanyName": "New College Lanarkshire",
        "specialization": "Technical Support",
        "MonthOfLeaving": "May",
        "YearOfLeaving": "2018",
        "Achievements": "Documented customers details and their reported issues. Conceive the probable cause of the issues and plan a solution. Execute the planned maintenance to resolve any hardware or software that had been reported. Return the hardware to the client with a description of what has been changed and fixed. Advised on how to possibly avoid similar issues in the future."
       }
    ],
    "portfolio":[
      {
        "name":"Hyperoptimisation",
        "description":"This is the project that I worked on for my undergraduate dissertaion that explores the effect that hyperoptimisation has on the effectiveness of gradient descent and ADAM",
        "imgurl": "images/portfolio/hyperoptimiser.jpg",
        "projectlink": "youtube.com"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl": "images/portfolio/console.jpg",
        "projectlink": "youtube.com"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl": "images/portfolio/project2.png",
        "projectlink": "youtube.com"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl": "images/portfolio/phone.jpg",
        "projectlink": "youtube.com"
      }
    ]
  }
  
  export default resumeData