import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, getOverlayAlpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Backdrop from '@mui/material/Backdrop';
import useMediaQuery from '@mui/material/useMediaQuery';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#191919',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
}));



export default function ActionAreaCard() {
    const width = 350;
    const [openLeft, setOpenLeft] = React.useState(false);
    const [openCent, setOpenCent] = React.useState(false);
    const [openRight, setOpenRight] = React.useState(false);


    const handleCloseLeft = () => {
        setOpenLeft(false);
    };
    const handleToggleLeft = () => {
        setOpenLeft(!openLeft)
    };


    const handleCloseCent = () => {
        setOpenCent(false);
    };
    const handleToggleCent = () => {
        setOpenCent(!openCent)
    };


    const handleCloseRight = () => {
        setOpenRight(false);
    };
    const handleToggleRight = () => {
        setOpenRight(!openRight)
    };

    const mobile = useMediaQuery('(min-width:768px)');
    const overlay = useMediaQuery('(min-width:900px)');

    var direction = "row"
    var align = "left"
    if (!mobile) {
        direction = "column"
    }
    else {
        direction = "row"
    }

    if (!overlay) {
        align = "center"
    }
    else {
        align = "left"
    }
    return (
    <div>
            <Grid container spacing={2} direction={direction}>
                <Grid xs display="flex" justifyContent="center" alignItems="center">
                <Card sx={{ maxWidth: width }} onClick={handleToggleLeft}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="140"
                        image="images/portfolio/hyperoptimiser.jpg"
                        alt="Python Code"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Hyperoptimiser
                            </Typography>
                            <Typography variant="body2" color="text.secondary" fontSize="1.25rem">
                                Undergraduate dissertation project that looks into the effectiveness of hyperoptimisation.
                            </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid>
                                    <Item>Python</Item>
                                </Grid>
                                <Grid>
                                    <Item>Machine Learning</Item>
                                </Grid>
                                <Grid>
                                    <Item>Pytorch</Item>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>

            <Grid xs display="flex" justifyContent="center" alignItems="center">
                    <Card sx={{ maxWidth: width }} onClick={handleToggleCent}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            image="images/portfolio/covidVis.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Visualisation of SIMD
                        </Typography>
                            <Typography variant="body2" color="text.secondary" fontSize="1.25rem">
                                An interactive visualisation of SIMD data that covers education, employment and income.
                        </Typography>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid>
                                <Item>Data Visualisation</Item>
                            </Grid>
                            <Grid>
                                <Item>JavaScript</Item>
                            </Grid>
                            <Grid>
                                <Item>D3</Item>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>

            <Grid xs display="flex" justifyContent="center" alignItems="center">
                    <Card sx={{ maxWidth: width }} onClick={handleToggleRight}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="images/portfolio/F20DL.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                COVID-19 Detection from Chest X-ray
                        </Typography>
                            <Typography variant="body2" color="text.secondary" fontSize="1.25rem">
                                An investigation into the effectiveness of several classification algorithms within Sklearn.
                        </Typography>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid>
                                    <Item>Python</Item>
                                </Grid>
                                <Grid>
                                    <Item>Sklearn</Item>
                                </Grid>
                                <Grid>
                                    <Item>Machine Learning</Item>
                                </Grid>
                                <Grid>
                                    <Item>Tensorflow</Item>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>

        <Backdrop
                sx={{ color: '#ebeeee', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLeft}
            onClick={handleCloseLeft}
            >   
                <Card sx={{ maxWidth: 1080 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent={align}
                        alignItems="stretch"
                        spacing={0.5}
                    >
                    <Grid item xs={7} md={7}>
                            <img
                                src="images/portfolio/hyperoptimiser.jpg"
                            />
                    </Grid>
                        <Grid xs={6} md={5} >
                            <h2>Hyperoptimiser</h2>
                            This project was part of my undergraduate dissertation project that had the goal of using gradient descent to optimise other gradient descent algorithms in a way that was more effective at obtaining the ideal hyperparameters than other strategies. 
                            <br /><br />
                            A neural network was trained on the identification of handwritten numbers using the MNIST dataset and then evalulated on its ability to effectively optimise the weights of a neural network using the gradient descent optimisation tower. While the accuracy of the model was important it was also crucial that this technique remains less computationally costly than a brute force approach.
                        </Grid>
                    </Grid>  
                </Card>
            </Backdrop>

            <Backdrop
                sx={{ color: '#ebeeee', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openCent}
                onClick={handleCloseCent}
            >
                <Card sx={{ maxWidth: 1080 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent={align}
                        alignItems="stretch"
                        spacing={0.5}
                    >
                        <Grid item xs={7} md={7}>
                            <img
                                src="images/portfolio/covidVis.jpg"
                            />
                        </Grid>
                        <Grid xs={6} md={5} >
                            <h2>Visualisation of SIMD</h2>
                            This project was completed as part of a data visualisation course I attended in my last year of university, in which I created an interactive visualisation of the SIMD dataset that allows the user to compare various locaitons
                            throughout Scotland with each other.
                            <br /><br />
                            You can find out more about the SIMD dataset <a href="https://www.gov.scot/collections/scottish-index-of-multiple-deprivation-2020/">here</a>
                        </Grid>
                    </Grid>
                </Card>
            </Backdrop>

            <Backdrop
                sx={{ color: '#ebeeee', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openRight}
                onClick={handleCloseRight}
            >
                <Card sx={{ maxWidth: 1080 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent={align}
                        alignItems="stretch"
                        spacing={0.5}
                    >
                        <Grid item xs={7} md={7}>
                            <img
                                src="images/portfolio/F20DL.jpg"
                            />
                        </Grid>
                        <Grid xs={6} md={5} >
                            <h2>COVID-19 Detection from Chest X-ray</h2>
                            This project is a collection of 4 programs that each use a different Sklearn classification algorithm to attempt to classify whether a patiant has pnumonia, COVID-19 or neither based off a chest X-ray.
                            <br /><br />
                            The 4 algorithms that have been used are: Gaussian Naive Bayes, K-Means clustering, Decision Trees and Multilayer Perceptron
                        </Grid>
                    </Grid>
                </Card>
            </Backdrop>
    </div>
    );
}