import React, { Component } from 'react';
import Cards from './Cards'

export default class Porfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Check Out Some of My Work.</h1>
                    <div id="portfolio-wrapper" className="cards">
                        <Cards />       
            </div>
        </div>
      </div>
  </section>
        );
  }
}